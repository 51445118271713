//= ===== FONT SIZES ======//
export const heading1 = '2.5rem';
export const heading2 = '2rem';
export const heading3 = '1.5rem';
export const heading4 = '1rem';
export const heading5 = '0.75rem';

export const bodyHero = '1.25';
export const body1 = '1rem';
export const body2 = '0.75rem';

export const buttonText = '1rem';
